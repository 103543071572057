import { bool, func, string } from 'prop-types'
import React from 'react'

import { FiltersPropTypes } from '../../shared/prop-types'

const Facet = ({
  activeFilters = [],
  expanded,
  filters,
  id,
  onClick,
  onFilterDelete,
  onFilterSelect,
  prefix = '',
  title,
}) => {
  return (
    <fieldset className='c-filter__category'>
      <legend>
        <button
          className='c-disclosure c-disclosure--filter'
          type='button'
          aria-expanded={expanded}
          aria-controls={`disclosure-filter-${prefix}-${id}`}
          onClick={(e) => toggleFacet(e, id)}
        >
          {title}
          {!expanded && activeFilters.length > 0 && (
            <span className='c-filter__selection'>{activeFilters.length}</span>
          )}
        </button>
      </legend>
      <div
        id={`disclosure-filter-${prefix}-${id}`}
        className='c-filter__list'
        hidden={!expanded}
      >
        <span className='c-filter__detail'>
          {activeFilters.length > 1
            ? `${activeFilters.length} items sélectionnés`
            : `${activeFilters.length || 0} item sélectionné`}
        </span>
        {filters.map(({ label, value }) => (
          <React.Fragment key={`${id}-${value}`}>
            <input
              checked={isFilterActive(value)}
              id={`${prefix}-${id}-${value}`}
              onChange={() => handleFilter({ label, value })}
              type='checkbox'
            />
            <label
              className='c-label c-label--checkbox c-label--highlight'
              htmlFor={`${prefix}-${id}-${value}`}
            >
              {label}
            </label>
          </React.Fragment>
        ))}
      </div>
    </fieldset>
  )

  // Manage filter selection or removal
  function handleFilter(filter) {
    if (isFilterActive(filter.value)) {
      onFilterDelete({ ...filter, id })
    } else {
      onFilterSelect({ ...filter, id })
    }
  }

  // Check if the given filter is active
  function isFilterActive(filterValue) {
    return activeFilters.some(({ value }) => value === filterValue)
  }

  function toggleFacet(event) {
    event.preventDefault()
    onClick(id)
  }
}

Facet.propTypes = {
  activeFilters: FiltersPropTypes,
  expanded: bool.isRequired,
  filters: FiltersPropTypes.isRequired,
  id: string.isRequired,
  onClick: func.isRequired,
  onFilterDelete: func.isRequired,
  onFilterSelect: func.isRequired,
  prefix: string,
  title: string.isRequired,
}

export default Facet
