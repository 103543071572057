import Cookies from 'js-cookie'
import { useState } from 'react'

import cookieSvg from '../../../svg/icon/cookies.svg'

const CONSENT_LABELS = {
  ad_user_data: 'L’envoi de données à des fins de publicité en ligne',
  ad_personalization: 'La publicité personnalisée',
  ad_storage: 'Le stockage lié à la publicité',
  analytics_storage: 'Le stockage lié aux données analytiques',
}
const COOKIE_NAME_PREFIX = 'gdpr.cookie_consent.gtag.'
const GRANTED = 'granted'
const DENIED = 'denied'

export default function ConsentBanner() {
  const sampleCookieValue = Cookies.get(
    `${COOKIE_NAME_PREFIX}${Object.keys(CONSENT_LABELS)[0]}`
  )
  const consentAlreadySet =
    sampleCookieValue === '' || sampleCookieValue === undefined
  const currentCookieValues = loadConsentCookie()
  const [display, setDisplay] = useState(consentAlreadySet)
  const [customize, setCustomize] = useState(false)
  const [tagsConsent, setTagsConsent] = useState(currentCookieValues)

  if (!display) {
    return (
      <button
        className='gdpr__cookie_consent-btn'
        onClick={toggleDisplay}
        title='Gérer mon consentement'
      >
        <img src={cookieSvg} alt='Logo d’accès à la gestion des cookies' />
        <span>Gérer mon consentement</span>
      </button>
    )
  }

  return (
    <div className='gdpr__cookie_consent'>
      <div className='gdpr__cookie_consent__text'>
        <img src={cookieSvg} alt='Logo d’accès à la gestion des cookies' />{' '}
        <h3>Cookies</h3>
        <p>
          Nous utilisons des cookies pour proposer des fonctionnalités
          personnalisées et analyser notre trafic. Vous pouvez accepter, refuser
          ou paramétrer.
        </p>
        <p>
          Pour en savoir plus et paramétrer ces cookies :&nbsp;
          <a
            href='https://gdpr.eu/cookies/'
            target='_blank'
            rel='noopener noreferrer'
          >
            cliquez ici
          </a>
          .
        </p>
      </div>
      {customize ? (
        <>
          <div className='gdpr__cookie_consent__text o-layout--center-x'>
            <p>Je donne mon consentement pour :</p>
            {Object.entries(tagsConsent).map(([key, value]) => (
              <div className='c-option__checkbox' key={key}>
                <input
                  id={key}
                  defaultChecked={value === GRANTED}
                  name={name}
                  onChange={() => handleConsentChange(key, value)}
                  type='checkbox'
                />
                <label
                  className='c-label c-label--checkbox c-label--checkbox c-label--highlight'
                  htmlFor={key}
                >
                  {CONSENT_LABELS[key]}
                </label>
              </div>
            ))}
          </div>
          <div className='gdpr__cookie_consent__buttons'>
            <button
              className='c-btn c-btn--secondary c-btn--small'
              onClick={toggleCustomization}
            >
              Masquer la personnalisation
            </button>
            <button
              className='c-btn c-btn--primary c-btn--small'
              onClick={handleValidation}
            >
              Valider mes choix
            </button>
          </div>
        </>
      ) : (
        <div className='gdpr__cookie_consent__buttons'>
          <button
            className='gdpr__cookie_consent__buttons__ok c-btn c-btn--primary c-btn--small'
            onClick={() => handleGlobalConsent(GRANTED)}
          >
            Tout accepter
          </button>
          <button
            className='gdpr__cookie_consent__buttons__ko c-btn c-btn--secondary c-btn--small'
            onClick={() => handleGlobalConsent(DENIED)}
          >
            Tout refuser
          </button>
          <button
            className='gdpr__cookie_consent__buttons__ko c-btn c-btn--secondary c-btn--small'
            onClick={toggleCustomization}
          >
            Paramétrer
          </button>
        </div>
      )}
    </div>
  )

  function handleConsentChange(key, value) {
    setTagsConsent({
      ...tagsConsent,
      [key]: value === GRANTED ? DENIED : GRANTED,
    })
  }

  // Validate consent customization
  function handleValidation({ updatedTags = tagsConsent }) {
    // Skip processing if gtag is not loaded
    if (typeof window.gtag === 'undefined') {
      toggleDisplay()
      return
    }

    const tagsToUpdate = {}
    // Loop over each tag key and check if acceptance has changed.
    // Ensure update sets denied options.
    for (const [key, value = DENIED] of Object.entries(updatedTags)) {
      // Always set key even if it has not changed
      tagsToUpdate[key] = value
      const actualConsentMode = currentCookieValues[key]

      if (actualConsentMode === value) {
        // console.debug(key, 'already set to', value)
        continue
      }

      // console.log(`Update GTag.${key} to ${value}`)
      const cookieName = `${COOKIE_NAME_PREFIX}${key}`
      Cookies.set(cookieName, value, { path: '/', expires: 365 })
    }

    // Only update consent once
    if (tagsToUpdate !== {}) {
      window.gtag('consent', 'update', tagsToUpdate)
    }

    toggleDisplay()
  }

  // Manage both acceptance and denial of all cookies/tracking behavior
  function handleGlobalConsent(consentMode) {
    // Skip processing if gtag is not loaded
    if (typeof window.gtag === 'undefined') {
      toggleDisplay()
      return
    }

    // Loop over each tag key and check if acceptance has changed
    const updatedTags = Object.keys(tagsConsent).reduce((acc, key) => {
      acc[key] = consentMode
      return acc
    }, {})

    // Only update state once (prevent multiple render).
    // Ensure validation is called after state is updated.
    setTagsConsent(updatedTags)
    handleValidation({ updatedTags })
  }

  // Load actual consent values from cookies
  function loadConsentCookie() {
    return Object.keys(CONSENT_LABELS).reduce((acc, key) => {
      const cookieName = `${COOKIE_NAME_PREFIX}${key}`
      acc[key] = Cookies.get(cookieName)
      return acc
    }, {})
  }

  function toggleCustomization() {
    setCustomize(!customize)
  }

  function toggleDisplay() {
    setDisplay(!display)
  }
}
